'use strict';

Gri.module({
  name: 'icon-menu-alpha',
  ieVersion: null,
  $el: $('.icon-menu-alpha'),
  container: '.icon-menu-alpha',
  fn: function () {
    tofas.iconMenuAlpha = {
      el : $('.icon-menu-alpha'),
      init: function() {
        tofas.iconMenuAlpha.click();
      },
      click: function() {
        tofas.iconMenuAlpha.el.on('click', function(){
          if($(this).hasClass('opened')){
            tofas.iconMenuAlpha.close($(this));
          }else{
            tofas.iconMenuAlpha.open($(this));
          }
        });
      },
      open: function(selector) {
		  if(selector != ""){
			selector.addClass('opened');
		  }
		  $('.wrapper').addClass('menu-opened');
		  $('.menu-alpha').addClass('active');
		  tofas.buttonSearch.menu("open");
      },
      close: function(selector) {
		if(selector != ""){
			selector.removeClass('opened');
		}
        $('.wrapper').removeClass('menu-opened');
        $('.menu-alpha').removeClass('active');
        tofas.buttonSearch.menu("close");
      }
    }

    /**/

    tofas.iconMenuAlpha.init();

  }
});
